<template>
  <div class="mask">
    <div class="edit-box">
      <i class="close" @click.stop="onClose"></i>
      <h5>{{type=='add'?'添加':'编辑'}}收货地址</h5>
      <ul>
        <li>
          <label>收货人</label>
          <div>
            <input type="text" v-model="name" placeholder="收货人姓名" />
          </div>
        </li>
        <li>
          <label>手机号</label>
          <div>
            <input type="tel" v-model="tel" placeholder="收货人" />
          </div>
        </li>
        <li>
          <label>地区</label>
          <div class="right" @click="popupVisible = true">
            <p v-if="area.length<3">点击选择</p>
            <p style="color:#333" v-else>{{area.join("-")}}</p>
          </div>
        </li>
        <li>
          <label>详细地址</label>
          <div>
            <textarea v-model="address" placeholder="如街道、小区、门牌号、乡镇、村"></textarea>
          </div>
        </li>
      </ul>
      <button class="btn" @click.stop="onSave">保存</button>
    </div>
    <mt-popup v-model="popupVisible" position="bottom" style="width:100%;">
      <div class="address-select-head">
        <span @click="popupVisible=false">取消</span>
        <span @click="changeArea">确定</span>
      </div>
      <mt-picker
        :slots="dateSlots"
        @change="onDateChange"
        :visible-item-count="7"
        :show-toolbar="false"
      ></mt-picker>
    </mt-popup>
  </div>
</template>

<script>
import { addressData } from "@/plugins/untils";
import { reqEditAddress } from "@/network/api";

let province = addressData.map(item => {
  return item.name;
});

let city = addressData[0].children.map(item => {
  return item.name;
});

let area = addressData[0].children[0].children.map(item => {
  return item.name;
});

export default {
  name: "EditAddress",
  data() {
    return {
      popupVisible: false,
      buttonBottom: 0,
      selectArea: [],
      dateSlots: [],
      id: this.info.id || null,
      name: this.info.name || "",
      tel: this.info.tel || "",
      area: this.info.area || [],
      address: this.info.address || "",
      default: this.info.default || 0
    };
  },
  props: {
    type: {
      type: String,
      default: "add"
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    // console.log(addressData, this.area);
    let i = Math.max(0, province.indexOf(this.area[0]));
    city = addressData[i].children.map(item => item.name);
    let j = Math.max(0, city.indexOf(this.area[1]));
    area = addressData[i].children[j].children.map(item => item.name);
    let q = Math.max(0, area.indexOf(this.area[2]));

    this.dateSlots = [
      {
        flex: 1,
        defaultIndex: i,
        values: province,
        className: "slot1",
        textAlign: "center"
      },
      {
        divider: true,
        content: "-",
        className: "slot2"
      },
      {
        flex: 1,
        defaultIndex: j,
        values: city,
        className: "slot3",
        textAlign: "center"
      },
      {
        divider: true,
        content: "-",
        className: "slot4"
      },
      {
        flex: 1,
        defaultIndex: q,
        values: area,
        className: "slot5",
        textAlign: "center"
      }
    ];
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSave() {
      if (this.name == "") {
        return this.$toast({ message: "请输入收货人" });
      }
      if (this.tel == "") {
        return this.$toast({ message: "请输入收货人手机号" });
      }
      if (!/^1\d{10}$/.test(this.tel)) {
          return this.$toast({
            message: "手机号有误，请重新填写",
          });
      }
      if (this.area.length < 3) {
        return this.$toast({ message: "请选择地区" });
      }
      if (this.address == "") {
        return this.$toast({
          message: "详细地址"
        });
      }
      reqEditAddress({
        id: this.id,
        name: this.name,
        phone: this.tel,
        province: this.area[0],
        city: this.area[1],
        area: this.area[2],
        add: this.address,
        default: this.default
      }).then(res => {
        const { code, msg, backdata } = res;
        this.$toast({ message: msg });
        this.$emit("save");
      });
    },
    changeArea() {
      this.popupVisible = false;
      if (this.selectArea.length) {
        this.area = this.selectArea;
      } else {
        if (this.area.length) return;
        this.area = [province[0], city[0], area[0]];
      }
    },
    onDateChange(picker, values) {
      let i = province.indexOf(values[0]);
      if (i == -1) return;
      let _city = addressData[i].children.map(item => item.name);
      picker.setSlotValues(1, _city);
      let j = _city.indexOf(values[1]);
      if (j == -1) return;
      let area =
        addressData[i].children[j].children &&
        addressData[i].children[j].children.map(item => item.name);
      picker.setSlotValues(2, area);
      this.selectArea = values;
    }
  }
};
</script>

<style lang="scss" scoped>
$w: 5.3rem;
$h: 6.52rem;
.address-select-head {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 15px;
  span {
    cursor: pointer;
    &:first-of-type {
      color: #333;
    }
    &:last-of-type {
      color: #5cbfb0;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0rem;
    right: 0;
    height: 1px;
    background: #e1e1e1;
    transform: scaleY(0.5);
    bottom: 0;
  }
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.edit-box {
  width: $w;
  height: $h;
  background: #ffffff;
  border-radius: 0.08rem;
  left: 50%;
  top: 50%;
  margin-left: -($w/2);
  margin-top: -($h/2);
  position: absolute;
  z-index: 1000;
  .btn {
    width: 4.7rem;
    height: 0.88rem;
    background: linear-gradient(0deg, #328f96 0%, #69c4b8 100%);
    border-radius: 0.44rem;
    cursor: pointer;
    font-size: 0.34rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.19rem auto;
    border: 0;
    &:focus {
      outline: 0;
    }
  }
  ul {
    box-sizing: border-box;
    li {
      box-sizing: border-box;
      padding-left: 0.31rem;
      height: 0.88rem;
      position: relative;
      display: flex;
      align-items: center;
      &:last-of-type {
        height: 1.59rem;
        padding-top: 0.31rem;
        align-items: flex-start;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0.31rem;
        right: 0;
        height: 1px;
        background: #e1e1e1;
        transform: scaleY(0.5);
        bottom: 0;
      }
      label {
        width: 1.1rem;
        margin-right: 0.14rem;
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      div {
        flex: 1;
        box-sizing: border-box;
        input,
        textarea {
          height: 100%;
          outline: 0;
          flex: 1;
          resize: none;
          border: 0;
          color: #333;
          &::-webkit-input-placeholder {
            font-size: 0.26rem;
            color: #666666;
          }
        }
        textarea {
          height: 1rem;
          line-height: 0.32rem;
        }
        &.right {
          background: url("../assets/images/ico-right.png") 93% center no-repeat;
          background-size: 0.11rem auto;
        }
        p {
          font-size: 0.26rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
  i {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    background: url("../assets/images/popup_btn_close.png") no-repeat;
    background-size: 100% auto;
    z-index: 9;
    position: absolute;
    right: -0.3rem;
    top: -0.3rem;
  }
  h5 {
    box-sizing: border-box;
    height: 0.97rem;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #e1e1e1;
      transform: scaleY(0.5);
    }
  }
}
</style>