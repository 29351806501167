<template>
  <div class="address-page">
    <Head title="收货地址管理" />
    <Warpper bg="#eff0ef" top="40" style="overflow: auto;">
      <ul class="address-list">
        <li v-for="(item,index) in list" :key="index">
          <div class="info" @click="onSelect(item)">
            <p>{{item.name}}，{{item.phone}}</p>
            <p>{{item.province}} {{item.city}} {{item.area}} {{item.add}}</p>
            <i class="icon-select mintui mintui-back" v-if="state=='select'"></i>
          </div>
          <div class="fn">
            <p :class="{'active':item.default==1}" @click.stop="changeDefault(item.id,index)">
              <span></span>
              {{item.default==1?"已":""}}设置为默认
            </p>
            <div>
              <span @click.stop="onDel(item.id,index)">删除</span>
              <span @click.stop="onEdit(item,index)">修改</span>
            </div>
          </div>
        </li>
      </ul>
      <button @click.stop="onAdd" class="add-btn">添加收货地址</button>
    </Warpper>
    <EditAddress
      v-if="show"
      :type="editType"
      :info="editInfo"
      @close="onCloseEditAddress"
      @save="onSave"
    />
  </div>
</template>

<script>
import EditAddress from "components/EditAddress.vue";
import {
  reqGetAllAddress,
  reqSetAddressDefault,
  reqDelAddress,
  reqGetAddress,
  reqEditAddress
} from "@/network/api";
export default {
  components: {
    EditAddress
  },
  created() {
    if(this.$route.query.state == "select"){
      this.state = "select";
    }
    this.getData();
  },
  data() {
    return {
      state:"",
      userInfo: this.$store.state.user.userInfo,
      activeIndex: 0,
      editType: "add",
      editInfo: {
        name: "",
        tel: "",
        area: "",
        address: ""
      },
      show: false,
      list: []
    };
  },
  methods: {
    async onSelect(item){
      if(this.state!="select") return;
      await this.$store.dispatch('user/setAddress', item);
      this.$router.back();
    },
    changeDefault(id, index) {
      reqSetAddressDefault({
        id
      }).then(res => {
        const { code, msg } = res;
        this.$toast({ message: msg });
        if (code != 0) return;
        this.getData();
      });
    },
    getData() {
      reqGetAllAddress({
        id: this.userInfo.id
      }).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.list = backdata.adds;
      });
    },
    onDel(id, index) {
      this.$MessageBox
        .confirm("确定要删除该地址么")
        .then(action => {
          this.$toast({
            message: "删除成功"
          });
          reqDelAddress({
            id
          }).then(res => {
            const { code, msg, backdata } = res;
            if (code != 0) return this.$toast({ message: msg });
            this.getData();
          });
        })
        .catch(() => {});
    },
    onCloseEditAddress() {
      this.show = false;
    },
    onSave() {
      this.getData();
      this.onCloseEditAddress();
    },
    onEdit(item, index) {
      (this.editType = "edit"),
        (this.editInfo = {
          id: item.id,
          name: item.name,
          tel: item.phone,
          area: [
            item.province,
            item.city.indexOf("市辖区") != -1 ? "市辖区" : item.city,
            item.area
          ],
          address: item.add,
          default: item.default
        });
      this.show = true;
    },
    onAdd() {
      (this.editType = "add"),
        (this.editInfo = {
          id: null,
          name: "",
          tel: "",
          area: "",
          address: "",
          default: 0
        });
      this.show = true;
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.icon-select{
  transform: rotate(180deg);
  position: absolute;
  right: .2rem;
  top: 50%;
  margin-top: -.2rem;
}
.address-list > li {
  width: 100%;
  min-height: 2.48rem;
  background-color: #fff;
  box-sizing: border-box;
  &:not(:last-of-type) {
    margin-bottom: 0.22rem;
  }
  .fn {
    box-sizing: border-box;
    padding: 0 0.3rem;
    height: 0.87rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      span {
        cursor: pointer;
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: 0.41rem;
      }
    }
    p {
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        width: 0.3rem;
        height: 0.3rem;
        background: url("../../assets/images/icon_circular_gray.png") no-repeat;
        background-size: 100% auto;
        margin-right: 0.14rem;
        display: inline-block;
      }
    }
    p.active {
      color: #fa4e4b;
      span {
        background-image: url("../../assets/images/icon_right_red.png");
      }
    }
  }
  .info {
    min-height: 1.6rem;
    box-sizing: border-box;
    padding: 0.44rem 0.3rem;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 7.2rem;
      height: 1px;
      background: #e1e1e1;
      left: 50%;
      transform: scaleY(0.5) translateX(-50%);
    }
    p {
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      &:not(:last-of-type) {
        margin-bottom: 0.2rem;
      }
    }
  }
}
.add-btn {
  width: 6.3rem;
  height: 0.88rem;
  background: linear-gradient(0deg, #328f96 0%, #69c4b8 100%);
  border-radius: 0.44rem;
  margin: 0.6rem auto;
  cursor: pointer;
  border: 0;
  font-size: 0.34rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: 0;
  }
  &::before {
    content: "";
    background: url("../../assets/images/icon_add_white.png") no-repeat;
    background-size: 100% auto;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.18rem;
    display: inline-block;
  }
}
</style>